import { Severity } from 'context/types';
import { useGetInvestmentProject } from 'hooks/queries/investment-project/investment-project';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  InvestmentPlanRoutes,
  InvestmentProjectRoutes,
  Routes,
} from 'routes/constants';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { InvestmentPlanLayout } from 'components/investment-plan-layout/investment-plan-layout';
import { PageLoader } from 'components/page-loader/page-loader';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { Tabs } from 'components/tabs/tabs';

import { UserModel } from 'models/user.model';

import { ReactComponent as IosShareRoundedIcon } from 'assets/file_download.svg';

import { instance, logoutOnInvalidToken } from 'services/external-api.service';

import { AppContext } from 'context';

import { onFileDownload } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { DEFAULT_SCORE_VALUE } from './tab-panels/project-evaluation-panel/project-evaluation-form/constants';

export const InvestmentProjectPage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { investmentProjectId, organizationId } = useParams();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data: investmentProject, isLoading: investmentProjectIsLoading } =
    useGetInvestmentProject(investmentProjectId);

  const [isProjectTableExporting, setIsProjectTableExporting] = useState(false);

  const downloadExcelProjectTable = async () => {
    if (!organizationId) return;

    try {
      setIsProjectTableExporting(true);

      const token = await logoutOnInvalidToken(auth);
      const response = await instance.get(
        `v2/investment-projects/${investmentProjectId}/export-project`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        },
      );

      onFileDownload(response.data, 'InvestmentProject.xlsx');
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.report.generate_report', {
          error: (error as any)?.message,
        }),
      });
    } finally {
      setIsProjectTableExporting(false);
    }
  };

  if (investmentProjectIsLoading || !investmentProject) {
    return <PageLoader />;
  }

  const { name, evaluation, displayId } = investmentProject;

  const path = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${InvestmentPlanRoutes.INVESTMENT_PROJECTS}`;

  const getInvestmentProjectTabs = [
    {
      label: t(
        'investment_projects_page.investment_project.project_details.title',
      ),
      value: InvestmentProjectRoutes.PROJECT_DETAILS,
      path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.PROJECT_DETAILS}`,
    },
    {
      label: t('investment_projects_page.investment_project.evaluation.title'),
      value: InvestmentProjectRoutes.EVALUATION,
      path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.EVALUATION}`,
    },
    {
      label: t(
        'investment_projects_page.investment_project.project_factsheet.title',
      ),
      value: InvestmentProjectRoutes.PROJECT_FACTSHEETS,
      path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.PROJECT_FACTSHEETS}`,
    },
    // {
    //   label: t(
    //     'investment_projects_page.investment_project.feasibility_in_details.title',
    //   ),
    //   value: InvestmentProjectRoutes.FEASIBILITY_IN_DETAILS,
    //   path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.FEASIBILITY_IN_DETAILS}`,
    // },
  ];

  const contentElement = (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="primary"
          sx={{ mr: 1 }}
          onClick={() => navigate(path)}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
          {t('investment_projects_page.investment_project.back_to_list')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography sx={{ display: 'block' }} fontSize={24} fontWeight={800}>
            ID-{displayId}
          </Typography>
          <Typography
            sx={{ display: 'block' }}
            fontSize={24}
            fontWeight={600}
            maxWidth={550}
          >
            {name[currentLanguage]}
          </Typography>
        </div>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            onClick={downloadExcelProjectTable}
            variant="outlined"
            disabled={isProjectTableExporting}
            startIcon={<IosShareRoundedIcon />}
          >
            {t('investment_projects_page.investment_project.export_project')}
          </Button>

          <Box
            sx={{ backgroundColor: 'success.main', p: 0.5, borderRadius: 1 }}
          >
            <Typography sx={{ fontSize: 22, color: 'common.white' }}>
              {String(evaluation?.score || DEFAULT_SCORE_VALUE)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );

  return (
    <InvestmentPlanLayout content={contentElement}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <PaperLayout borderRadius={4}>
            <Tabs
              tabsData={getInvestmentProjectTabs}
              defaultValue={InvestmentProjectRoutes.PROJECT_DETAILS}
            />

            <Box>
              <Outlet context={investmentProject} />
            </Box>
          </PaperLayout>
        </Grid>
      </Grid>
    </InvestmentPlanLayout>
  );
};
